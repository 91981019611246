<template>
  <div class="homeworkDetail">
    <div class="box">
      <div class="subject">
        <!--                    <div class="title">作业名称</div>-->
        <div class="content" style="position:relative;">
          <div class="text">
            <div class="topic" style="font-weight: 600;font-size: 16px">作业题目
              <span class="top-span">题型：{{ questionList[current-1].questionTypeName }}</span>
              <span class="top-span">类型：{{ questionList[current-1].categoryName }}</span>
              <span class="top-span">难度：{{ questionList[current-1].levelName }}</span>
            </div>
            <div class="questionContent" style="display: flex">
              <div style="float: left"> {{ current }}、</div>
              <div class="detail" style="float: left;width: 1100px" v-html="questionList[current-1].questionInformation" />
            </div>
            <div v-if="questionList[current-1].questionTypeName==='简答题'||questionList[current-1].questionTypeName==='编程题'" class="btn">
              <el-button type="text" @click="downLoad">下载附件</el-button>
            </div>
          </div>
          <!--上一题-->
          <div class="circle_bu" style="position: absolute;right: -30px;bottom: 30px;" @click="previous">
            <div :class="current===1?'previousTop':'previous'">
              上一题
            </div>
          </div>
        </div>
      </div>
      <div style="position:relative;">
        <!--下一题-->
        <div class="circle_bu" style="position: absolute;right: -30px;top: -40px" @click="next">
          <div :class="current===total?'nextBotton':'next'">
            下一题
          </div>
        </div>
        <!--提交-->
        <div class="circle_bu" style="position: absolute;right: -30px;top: 30px" @click="submitPre">
          <div class="right-submit">
            提交
          </div>
        </div>
        <!-- 作答区域-->
        <div v-if="questionList[current-1].questionTypeName==='选择题'" class="studentAnswer">
          <div>
            <div class="title">解答 </div>
          </div>
          <div>
            <div class="input-c"><el-select v-model="questionList[current-1].studentAnswer" placeholder="请选择答案" size="small">
              <el-option
                v-for="dict in answerList"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select></div>
          </div>
        </div>
        <div v-else class="studentAnswer">
          <div>
            <div class="title">解答</div>
          </div>
          <div>
            <div><el-input
              v-model="questionList[current-1].studentAnswer"
              class="text"
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 6}"
              :rows="2"
              resize="none"
              placeholder="填空题答案请用英文逗号(',')分隔开"
            /></div>
          </div>
          <div style="display: flex">
            <div v-if="questionList[current-1].questionTypeName!=='填空题'">
              <FileUpload :key="questionList[current-1].questionId" :btn-value="'上传答案附件'" :value="questionList[current-1].urlModel" :limit="1" :type="{type:5}" @input="inputList" />
            </div>

          </div>
        </div>
        <div v-if="questionList[current-1].publishType!==0&&questionList[current-1].questionTypeName==='选择题'" class="answer">
          <div class="content">
            <div class="title">答案</div>
            <div>
              <div class="input-c"><el-select v-model="questionList[current-1].answerInformation" placeholder="请选择答案" size="small" disabled>
                <el-option
                  v-for="dict in answerList"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select></div>
            </div></div>
        </div>
        <div v-if="questionList[current-1].publishType!==0&&questionList[current-1].questionTypeName!=='选择题'" class="answer">
          <div class="content">
            <div class="title">答案</div>
            <div class="text" v-html="questionList[current-1].answerInformation" />
            <div class="btn"><el-button v-if="questionList[current-1].questionTypeName==='简答题'||questionList[current-1].questionTypeName==='编程题'" type="text" @click="downLoadAnswer">下载附件</el-button></div>
          </div>
        </div>
      </div>

      <div class="page">
        <!--              <div class="submit-c">-->
        <!--                <el-button  style="margin-left: 200px;color: #FFFFFF" type="primary" @click="submitPre" size="medium" round>提交作业</el-button>-->
        <!--              </div>-->
        <div class="btn" style="float: right">
          <!--                    <el-button type="text" @click="previous">上一题</el-button>-->
          <span style="color: #39A5EF;padding-left: 19px">第{{ current }}题</span>
          <span style="padding-right: 19px">/共{{ total }}题</span>
          <!--                    <el-button type="text" @click="next">下一题</el-button>-->
        </div>
      </div>
      <!--            <div>-->

      <!--            </div>-->
    </div>
    <el-dialog
      :visible.sync="submitable"
      :close-on-click-modal="false"
      width="40%"
      custom-class="mydialog"
      center
    >
      <div slot="title" class="head">
        提交作业
      </div>

      <el-input
        v-model="feedback"
        type="textarea"
        :autosize="{ minRows: 9, maxRows: 9}"
        maxlength="500"
        show-word-limit="true"
        resize="none"
        placeholder="填写作业的完成情况，以及作业过程中遇到的问题"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="closePublish">取 消</el-button>
        <el-button type="primary" @click="workSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <indexBottom />
  </div>
</template>

<script>
import indexBottom from '../../components/indexBottom'
import FileUpload from '../../components/FileUpload'
import { mapState } from 'vuex'
import { getStudentInfoList, saveStudentAnswer, submitStudentAnswer } from '../../api/course'
export default {
  name: 'HomeworkDetail',
  components: { indexBottom, FileUpload },
  created() {
    // 处理字典值
    this.dealWithArrToMap()
    this.getDetail()
  },
  computed: {
    ...mapState({
      answerList: state => state.dict.dictTree.work_answer['arr'],
      ea_question_type: state => state.dict.dictTree.ea_question_type['arr'],
      ea_question_category: state => state.dict.dictTree.ea_question_category['arr'],
      ea_question_difficulty: state => state.dict.dictTree.ea_question_difficulty['arr']
    })
  },
  data() {
    return {
      current: 1,
      total: 1,
      select: '',
      submitable: false,
      feedback: '',
      questionList: [{ questionName: '', answerInformation: '', questionInformation: '', publishType: 0 }],
      questionTypeMap: {},
      questionCategoryMap: {},
      questionDifficultyMap: {},
      question: {
        title: '题目题目题目',
        content: '内容内容内容内容内容内容'
      },
      // 上传附件
      answerFile: []
    }
  },
  methods: {
    getDetail() {
      getStudentInfoList({ taskLogId: this.$route.query.id }).then(res => {
        if (res.code === 200) {
          this.total = res.total
          if (res.rows.length > 0) {
            res.rows.forEach(item => {
              if (item.studentAnswerUrls !== null && item.studentAnswerUrls !== '') {
                item.urlModel = [{
                  url: item.studentAnswerUrls,
                  name: '附件'
                }]
              } else {
                item.urlModel = ''
              }
            })
          }
          this.questionList = res.rows
          console.log(this.questionList)
          this.dealWithName()
        }
      })
    },
    inputList(data) {
      console.log(data)
      if (data.length > 0) {
        this.questionList[this.current - 1].studentAnswerUrls = data.map(i => i.url).toString()
      } else {
        this.questionList[this.current - 1].studentAnswerUrls = ''
      }
    },
    // 处理当前题的类型等名称
    dealWithName() {
      this.questionList[this.current - 1].questionTypeName = this.questionTypeMap[this.questionList[this.current - 1].questionType + '']
      this.questionList[this.current - 1].categoryName = this.questionCategoryMap[this.questionList[this.current - 1].type + '']
      this.questionList[this.current - 1].levelName = this.questionDifficultyMap[this.questionList[this.current - 1].level + '']
    },
    previous() {
      if (this.current === 1) {
        this.$message.warning('当前已是第一题')
      } else {
        if ((this.questionList[this.current - 1].studentAnswer === '' || this.questionList[this.current - 1].studentAnswer === null) && (
          this.questionList[this.current - 1].studentAnswerUrls === '' || this.questionList[this.current - 1].studentAnswerUrls === null)) {
          this.current--
          console.log('上一题')
          this.dealWithName()
        } else {
          const obj = {
            id: this.questionList[this.current - 1].studentQuestionId,
            answerInformation: this.questionList[this.current - 1].studentAnswer,
            answerUrls: this.questionList[this.current - 1].studentAnswerUrls
          }
          // 保存本题
          saveStudentAnswer(obj).then(res => {
            console.log(res)
            if (res.code !== 200) {
              this.$message({
                type: 'success',
                message: '保存失败，请联系老师!'
              })
            }
            // 进行及时的回显
            if (this.questionList[this.current - 1].studentAnswerUrls !== '') {
              this.questionList[this.current - 1].urlModel = [{
                url: this.questionList[this.current - 1].studentAnswerUrls,
                name: '附件'
              }]
            } else {
              this.questionList[this.current - 1].urlModel = ''.concat()
            }

            this.current--
            console.log('上一题')
            this.dealWithName()
          })
        }
      }
    },
    next() {
      if (this.current === this.total) {
        this.$message.warning('当前已是最后一题')
      } else {
        if ((this.questionList[this.current - 1].studentAnswer === '' || this.questionList[this.current - 1].studentAnswer === null) && (
          this.questionList[this.current - 1].studentAnswerUrls === '' || this.questionList[this.current - 1].studentAnswerUrls === null)) {
          this.current++
          console.log('下一题')
          this.dealWithName()
        } else {
          const obj = {
            id: this.questionList[this.current - 1].studentQuestionId,
            answerInformation: this.questionList[this.current - 1].studentAnswer,
            answerUrls: this.questionList[this.current - 1].studentAnswerUrls
          }
          // 保存本题，
          saveStudentAnswer(obj).then(res => {
            console.log(res)

            if (res.code !== 200) {
              this.$message({
                type: 'success',
                message: '保存失败，请联系老师!'
              })
            }
            // 进行及时的回显
            if (this.questionList[this.current - 1].studentAnswerUrls !== '') {
              this.questionList[this.current - 1].urlModel = [{
                url: this.questionList[this.current - 1].studentAnswerUrls,
                name: '附件'
              }].concat()
            } else {
              delete this.questionList[this.current - 1].urlModel
            }
            console.log(this.questionList)
            this.current++
            console.log('下一题')
            this.dealWithName()
          })
        }
      }
    },
    // 提交前的弹框
    submitPre() {
      this.submitable = true
    },
    // 取消提交
    closePublish() {
      this.submitable = false
    },
    workSubmit() {
      if (this.feedback === '') {
        this.$message.error('请填写反馈信息')
      } else {
        const obj = {
          id: this.questionList[this.current - 1].studentQuestionId,
          answerInformation: this.questionList[this.current - 1].studentAnswer,
          answerUrls: this.questionList[this.current - 1].studentAnswerUrls,
          publishId: this.questionList[this.current - 1].classPublishId,
          taskFeedback: this.feedback
        }
        console.log(obj)
        submitStudentAnswer(obj).then(res => {
          console.log(res)
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '提交成功'
            })
            this.submitable = false
            this.feedback = ''
            this.$router.go(-1)
          }
        })
      }
    },
    downLoad() {
      console.log(this.current)
      console.log(this.questionList[this.current - 1])
      if (this.questionList[this.current - 1].questionUrls == '' || this.questionList[this.current - 1].questionUrls == null || this.questionList[this.current - 1].questionUrls == undefined) {
        this.$message.warning('该试题没有附件')
      } else {
        this.$download.name(this.questionList[this.current - 1].questionUrls)
      }
    },
    downLoadAnswer() {
      if (this.questionList[this.current - 1].answerUrls == '' || this.questionList[this.current - 1].answerUrls == null || this.questionList[this.current - 1].answerUrls == undefined) {
        this.$message.warning('该试题答案没有附件')
      } else {
        this.$download.name(this.questionList[this.current - 1].answerUrls)
      }
    },
    // 处理题型，难度，类型字典值为map方便使用
    dealWithArrToMap() {
      this.ea_question_type.forEach(item => {
        const key = item.value
        this.questionTypeMap[key] = item.label
      })
      this.ea_question_category.forEach(item => {
        const key = item.value
        this.questionCategoryMap[key] = item.label
      })
      this.ea_question_difficulty.forEach(item => {
        const key = item.value
        this.questionDifficultyMap[key] = item.label
      })
    }
  }
}
</script>

<style scoped lang="less">
    /*/deep/.el-dialog__header .el-dialog__title{*/
    /*    color: #66b1ff !important;*/
    /*}*/
    /*/deep/.el-dialog{*/
    /*    min-height: 380px;*/
    /*}*/

    .homeworkDetail {
        /deep/.el-dialog {
            min-height: 200px;
        }
    }

    /deep/.mydialog{
        color: #66b1ff
    }
    .homeworkDetail{
        margin: 0 auto 17px;
        .box{
            margin: 0 auto;
            width: 1200px;
            box-sizing: border-box;
            /*background: #FFFFFF;*/
            >.subject{
                //padding-top: 19px;
            }
            .title{
                text-align: left;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                margin-bottom: 20px;
            }
            .content{
                padding: 20px;
                margin: 0 auto;
                /*width: 1160px;*/
                min-height: 150px;
                /*background: #EEF5FB;*/
                background: white;
                /*overflow: auto;*/
                border-radius: 10px;
                .text{
                    min-height: 250px;
                    text-align: left;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    .topic{
                        font-size: 18px;
                      .top-span{
                        font-size: 14px;
                        display: inline-block;
                        padding: 0 20px;
                      }
                    }
                  .questionContent{
                    //overflow: auto;
                    word-break: break-word;
                    margin-top: 20px;
                    .detail{
                      min-height: 150px;
                    }
                  }
                }
              .btn{
                margin-top: 10px;
                text-align: left;
                /*margin-right: 20px;*/
              }
            }
            >.page{
                height: 20px;
                text-align: center;
                margin: 20px 24px 20px 0;
                .el-button{
                    color: #999999;
                }
                span{
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
    }
    .studentAnswer{
        margin: 20px auto;
        background: white;
        border-radius: 10px;
        padding: 20px;
        box-sizing: border-box;
        height: 350px;
      .text{
        padding: 20px 0;
        text-align: left;
        min-height: 200px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        .topic{
          font-size: 18px;
        }
      }
        .title{
            margin-bottom: 20px;
        }

    }

    .input-c{
      text-align: left;
    }
    .submit-c{
      text-align: center;
      padding: 10px 0;
      margin-bottom: 20px;
    }
    .input-textarea{

    }

    .text-center{
        text-align: center;
    }
    .circle_bu{
      cursor: pointer;
      .previous{
        width: 60px;
        height:60px;
        color: #4d97fe;
        font-weight: 600 ;
        font-size:14px;
        line-height: 60px;
        box-shadow: 0px 0px 10px  rgba(0,41,127,0.2);
        border-radius: 50px;
        background-color: #FFFFFF
      }
      .previousTop{
        width: 60px;
        height:60px;
        color: #FFFFFF;
        font-weight: 600 ;
        font-size:14px;
        line-height: 60px;
        box-shadow: 0px 0px 10px  rgba(0,41,127,0.2);
        border-radius: 50px;
        background-color: #c5c8cb
      }
      .next{
        width: 60px;
        height:60px;
        color: #4d97fe;
        font-weight: 600 ;
        font-size:14px;
        line-height: 60px;
        box-shadow: 0px 0px 10px  rgba(0,41,127,0.2);
        border-radius: 50px;
        background-color: #FFFFFF
      }
      .nextBotton{
        width: 60px;
        height:60px;
        color: #FFFFFF;
        font-weight: 600 ;
        font-size:14px;
        line-height: 60px;
        box-shadow: 0px 0px 10px  rgba(0,41,127,0.2);
        border-radius: 50px;
        background-color: #c5c8cb
      }
      .right-submit{
        padding: 10px;
        width: 60px;
        height:60px;
        color: #FFFFFF;
        font-size:14px;
        line-height: 40px;
        box-shadow: 0px 0px 10px rgba(0,41,127,0.2);
        border-radius: 50px;
        background-color: #4d97fe
      }
      -webkit-user-select:none;
      -moz-user-select:none;
      -ms-user-select:none;
      user-select:none;
    }

</style>
